import React from "react";
import MandantRapView from "../../../components/filecontent/MandantRapView";

type PropsType = {
  params: { fileId: string };
};

// markup
const Page = ({ params }: PropsType) => {
  return (
    <div>
      <MandantRapView backendReference={params.fileId} editable={false} />
    </div>
  );
};

export default Page;
